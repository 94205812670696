import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import moment from 'moment-timezone'

import ApiRequest from '@/core/ApiRequest'
import RoomModel from '@/core/models/Room'
import EventModel from '@/core/models/Event'
import { FETCH_ROOMS_LIST } from '@/store/root/actions'
import { GET_API_REQUEST, GET_ROOMS_LIST } from '@/store/root/getters'

@Component
export default class Calendar extends Vue {
  public startShownDate: any = null
  public endShownDate: any = null

  public type: string = 'month'

  public types: Array<string> = ['month', 'week', 'day', '4day']

  public mode: string = 'stack'

  public modes: Array<string> = ['stack', 'column']

  public value: string = ''

  public events: Array<any> = []

  public maxEventsInDay: number = 0

  /**
   * Fetch rooms list.
   */
  @Action(FETCH_ROOMS_LIST)
  public fetchRoomsList: () => void

  /**
   * Get rooms list, based on global Vuex state.
   */
  @Getter(GET_ROOMS_LIST)
  public getRoomsList: RoomModel[]

  /**
   * Get api request object, based on global Vuex state.
   */
  @Getter(GET_API_REQUEST)
  public getApiRequest: ApiRequest

  /**
   * Get events between start and end date.
   * @param start   Start date.
   * @param end     End date.
   */
  public async getEvents ({ start, end }: { start: any, end: any }): Promise<any> {
    console.log(`getEvents ${start.date}, ${end.date}`)
    this.startShownDate = start.date
    this.endShownDate = end.date

    const response = await this.getApiRequest.send(
      'GET',
      'events?date_from=' +
        encodeURIComponent((new Date(`${start.date}T00:00:00`)).toISOString()) +
        '&date_to=' +
        encodeURIComponent((new Date(`${end.date}T23:59:59`)).toISOString()),
    )
    if (!response || !response.data) {
      return []
    }

    const eventsByDates: {[key: string]: any} = {}
    for (const event of response.data as any) {
      const days = Calendar.getDaysArray(event.date_from, event.date_to)
      for (const day of days) {
        const dateKey: string = moment(day.toISOString()).format('YYYY-MM-DD')
        if (!eventsByDates[dateKey] || !eventsByDates[dateKey][event.room_id]) {
          const obj: any = !eventsByDates[dateKey] ? {} : eventsByDates[dateKey]
          obj[event.room_id] = [event]
          eventsByDates[dateKey] = obj
          continue
        }
        eventsByDates[dateKey][event.room_id].push(event)
      }
    }

    const diffRooms: Array<any> = []
    const events = []
    for (const dateKey in eventsByDates) {
      const dateEvents = eventsByDates[dateKey]
      for (const roomKey in dateEvents) {
        const roomEvents = dateEvents[roomKey]
        const count = roomEvents.length
        const notCanceledEvents = roomEvents.filter((e: any) => e.status !== 'canceled')
        for (const ev of roomEvents) {
          if (!diffRooms.includes(ev.room_id)) {
            diffRooms.push(ev.room_id)
          }
        }
        const event = notCanceledEvents.length > 0 ? notCanceledEvents[0] : roomEvents[0]
        const name = event.room_name + (count > 1 ? ` (${count})` : '')
        const color = notCanceledEvents.length > 0
          ? event.room_color
          : 'blue-grey lighten-4'
        const start = new Date(dateKey)
        start.setHours(0, 0, 0)
        const end = new Date(dateKey)
        end.setHours(23, 59, 59)
        events.push({
          name,
          start: moment(start.toISOString()).format('YYYY-MM-DD'),
          end: moment(end.toISOString()).format('YYYY-MM-DD'),
          color,
          timed: false
        })
      }
    }
    this.maxEventsInDay = diffRooms.length

    this.events = events
    this.$nextTick(() => {
      this.setDayClickHandler()
      console.log('setDayClickHandler')
    })
  }

  public getEventColor (event: any): string {
    return event.color
  }

  public rnd (a: number, b: number): number {
    return Math.floor((b - a + 1) * Math.random()) + a
  }

  private static getDaysArray (start: string, end: string): Array<Date> {
    const dates = []
    const dt = new Date(start)
    dt.setHours(0, 0, 0, 0)
    const endDate = (new Date(end))
    endDate.setHours(23, 59, 59, 999)
    // eslint-disable-next-line no-unmodified-loop-condition
    for (; dt <= endDate; dt.setDate(dt.getDate() + 1)) {
      dates.push(new Date(dt))
    }
    return dates
  }

  public onDayClick (domEvent: any) {
    const target = (domEvent.target as any).closest('.v-calendar-weekly__day')
    const isOutside = target.classList.contains('v-outside')
    const element = target.querySelector('.v-calendar-weekly__day-label button.v-btn .v-btn__content')
    const dayNumber = element.textContent.replace(/\D/g, '')
    const date = new Date(this.startShownDate as any)
    date.setDate(dayNumber)
    if (isOutside) {
      date.setMonth(date.getMonth() + (dayNumber < 15 ? 1 : -1))
    }
    const resultDate = moment(date.toISOString()).format('YYYY-MM-DD')
    this.$router.push({ name: 'dayEvents', params: { date: resultDate } })
  }

  /**
   * Add onClick event handler for day element of calendar
   */
  public setDayClickHandler () {
    const elements = document.getElementsByClassName('v-calendar-weekly__day')
    for (const element of elements) {
      element.removeEventListener('click', this.onDayClick)
      element.addEventListener('click', this.onDayClick, true)
    }
  }

  /**
   * Created lifecycle hook. Fires when component instance is created.
   */
  public async created () {
    if (this.$route.query.date) {
      // console.log(`query.date=${this.$route.query.date}, casted=`, String(this.$route.query.date))
      this.value = String(this.$route.query.date)
    }
    await this.fetchRoomsList()
  }

  /**
   * VueJs mounted hook.
   */
  public mounted () {
    this.setDayClickHandler()
  }
}
